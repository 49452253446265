export interface FarmType {
  token1?: string
  token2?: string
  tokenOverride?: string
  symbol?: string
  addressByChain: any
  _pid: number
}

export const farmData: FarmType[] = [
  // NOTE: WE CANNOT STAKE ESEED - high risk and may cause issues with rewards.
  // MUST STAKE ESEED LPs

  {
    token1: 'GHC',
    token2: 'BNB',
    addressByChain: {
      '56': '0xcfd9ee6d907f62f03942ca2e6f94f40e35d29c2f',
      '97': '0xFfD96D5814CCFEB7ce7f49B2efbd032a82341C6c',
      '31337': '0x0000000000000000000000000000000000000000',
    },
    _pid: 0,
  },
  {
    token1: 'GHC',
    addressByChain: {
      '56': '0x683fae4411249ca05243dfb919c20920f3f5bfe0',
      '97': '0x17Bf1d234D148fD5F0D7A5aA06EC008CEF773217',
      '31337': '0x0000000000000000000000000000000000000000',
    },
    _pid: 1,
  },
]

export const addressesByChain: any = {
  FARM: {
    '56': '0x4ddf299216067821ac3bdf12a52e4707b9345a69',
    '97': '0xe215B93Aec6A38CA280eD70E600301Fc4D072cf0',
    '31337': '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
  },
  REWARD: {
    '56': '0x683fae4411249ca05243dfb919c20920f3f5bfe0',
    '97': '0x17Bf1d234D148fD5F0D7A5aA06EC008CEF773217'
  },
  BNB: {
    '56': '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    '97': '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F'
  },
}