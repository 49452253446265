import React, { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Button, ButtonProps, useWalletModal, Language } from '@pancakeswap/uikit'
import useI18n from 'hooks/useI18n'
import useAuth from 'hooks/useAuth'
import { EN, languages } from '../../config/localization/languages'
import translations from '../../config/localization/translations.json'

export const languageMap = new Map<Language['locale'], Record<string, string>>()
languageMap.set(EN.locale, translations)
const currentLanguage = EN
const translate = (key: string) => {
  console.log('translate', key)
  const translationSet = languageMap.has(currentLanguage.locale)
    ? languageMap.get(currentLanguage.locale)
    : languageMap.get(EN.locale)
  const translatedText = translationSet ? translationSet[key] || key : key

  // Check the existence of at least one combination of %%, separated by 1 or more non space characters
  const includesVariable = translatedText.match(/%\S+?%/gm)

  // if (includesVariable && data) {
  //   let interpolatedText = translatedText
  //   Object.keys(data).forEach((dataKey) => {
  //     const templateKey = new RegExp(`%${dataKey}%`, 'g')
  //     interpolatedText = interpolatedText.replace(templateKey, data[dataKey].toString())
  //   })

  //   return interpolatedText
  // }

  return translatedText
}

const ConnectWalletButton: React.FC<ButtonProps> = (props) => {
  const TranslateString = useI18n()
  const { login, logout } = useAuth()
  const { account } = useWeb3React()
  const { onPresentConnectModal } = useWalletModal(login, logout, account || undefined)

  return (
    <Button className="bgTheme" onClick={onPresentConnectModal} {...props}>
      Connect Wallet
    </Button>
  )
}

export default ConnectWalletButton
