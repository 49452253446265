import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useWalletModal } from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import GetPrice from 'utils/getPrice'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { formatNumber } from 'utils/formatBalance'
import './style.css'

const StarsBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url('${process.env.REACT_APP_PUBLIC_URL}/images/nebula.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex: 1;
`

const Logo = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
`

const ValueText = styled.div`
  margin-left: 10px;
  color: rgb(29, 162, 231);

  @media only screen and (min-width: 986px) {
    top: 0;
    width: max-content;
    width: -webkit-max-content;
    transform: translateY(23px);
  }
`

const TitleText = styled.div`
  padding-top: 5px;
  font-size: 32px;
  flex: 1;
  text-align: center;
  color: white;
  @media only screen and (max-width: 780px) {
    margin: 10px 0;
  }
`

const TitleTextBold = styled.div`
  font-weight: 600;
  display: contents;
  filter: drop-shadow(30px 10px 4px #4444dd);
`

const ConnectBtnContainer = styled.div`
  flex: 1;
  text-align: right;
`

const ConnectBtn = styled.button`
  border-width: 0px;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  background-color: rgb(29, 162, 231);
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background-color: rgba(178, 96, 220, 0.8);
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  background-color: rgba(1, 1, 1, 0.4);
  border-top: 1px solid darkgrey;
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: white;
`

const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const location = useLocation()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account || undefined)
  const [mainTokenPrice, setMainTokenPrice] = useState('')

  const { children } = props

  useEffect(() => {
    const intervalId = setInterval(() => {
      getPrice()
    }, 10000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  async function getPrice() {
    try {
      const rewardTokenClass = new GetPrice()
      await rewardTokenClass.init('rewardToken')
      const { pairPriceA: esbbPrice, pairPriceB: bbprice, price: esprice } = await rewardTokenClass.getLatestPrice()
      setMainTokenPrice(formatNumber(esprice.toNumber(), 12, 12))
    } catch (e) {
      // console.log('pe 01', e)
    }
  }
  console.log("onPresentConnectModal", onPresentConnectModal)
  return (
    <div className="egg-menu-container">
      {/* title bar */}
      <div className="title-bar">
        <div className="container content">
          {/* logo */}
          <LogoContainer>
            <Logo src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-circle.png`} />
            <ValueText>1 GHC = {mainTokenPrice ? `$${mainTokenPrice}` : 'loading...'}</ValueText>
          </LogoContainer>
          {/* title */}
          <TitleText className="title-text">
            <TitleTextBold>GALAXY HEROES</TitleTextBold> DEX
          </TitleText>
          {/* connect */}
          <ConnectBtnContainer>
            <ConnectBtn onClick={account ? onPresentAccountModal : onPresentConnectModal}>
              {account ? 'Connected' : 'Connect to a wallet'}
            </ConnectBtn>
          </ConnectBtnContainer>
        </div>
      </div>

      {/* menu bar */}
      <div className="menu-bar">
        <span style={{ fontSize: 12, color: '#2e2e2e', position: 'absolute' }}>v1.9</span>
        <div className="container content">
          <Link to="/swap" className={`menu-item ${location.pathname === '/swap' && 'active'}`}>
            Swap
          </Link>
          <Link to="/pool" className={`menu-item ${location.pathname === '/pool' && 'active'}`}>
            Pool
          </Link>
          <Link to="/farms" className={`menu-item ${location.pathname === '/farms' && 'active'}`}>
            Stake
          </Link>
        </div>
      </div>

      {/* content */}
      <div className="main-content">
        <StarsBG />
        {children}
      </div>

      {/* footer */}
      <Footer>
        <FooterContent className="container" style={{ justifyContent: 'space-between', opacity: 0.25 }}>
          <div>
            This dex was built with ❤ by the wizards at{' '}
            <a target="_blank" rel="noreferrer" href="https://highstack.co">
              Highstack.co
            </a>
          </div>
          <div style={{ textAlign: 'right' }}>
            *Galaxy Heroes Coin makes no promises and is not responsible for any losses or errors. Use at your own risk.
          </div>
        </FooterContent>
      </Footer>
    </div>
  )
}

export default Menu
