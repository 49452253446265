import React, { useState, useEffect } from 'react'
import { BigNumber, formatFixed } from '@ethersproject/bignumber'
import BigNumberJS from 'bignumber.js'
import Web3 from 'web3'
import { ethers } from 'ethers'
import useToast from 'hooks/useToast'
import { useContract } from 'hooks/useContract'
import { useModal } from '@pancakeswap-libs/uikit'
import {
  getBalanceString,
  getDecimalAmount,
  formatNumber,
  getBalanceAmount,
  getNumber,
  formatBalanceAmount,
} from 'utils/formatBalance'

import { useActiveWeb3React } from '../../hooks'
import * as FarmContract from '../../config/abis/Farm.json'
import * as IBEP20Contract from '../../config/abis/IBEP20.json'
import {
  FormInfo,
  StackDataContainer,
  SmallStackDataContainer,
  Label,
  BigLabel,
  Value,
  ColoredValue,
  Details,
  BlackChevronDown,
  BlackChevronUp,
  MediumStackDataContainer,
  LogoContainer,
  Logo,
  FirstLogo,
  SecondLogo,
  FormContainer,
  DetailsContainer,
  FarmsDetails,
  FarmDetailsData,
  FarmDetailsActions,
  FarmDetailsRow,
  FormActionButton,
} from './styleds'
import { addressesByChain } from './data'
import DepositWithdrawModal from './DepositWithdrawModal'

const Farm = (props) => {
  /* * STATE * */
  const { farmData, rewardTokenPrice, bnbPrice } = props
  const { _pid, tokenOverride, token1, token2 } = farmData
  const [showMore, setShowMore] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [web3, setWeb3] = useState(new Web3('https://bsc-dataseed1.binance.org:443'))

  const { toastSuccess, toastError } = useToast()

  const { account, chainId } = useActiveWeb3React()
  const [farmEnabled, setFarmEnabled] = useState(false)
  const [farmEnabledPending, setFarmEnabledPending] = useState(false)

  const [lpTokenBalance, setLpTokenBalance] = useState('0')
  const [stakingBalance, setStakingBalance] = useState('0')
  const [rewardTokenEarned, setRewardTokenEarned] = useState('0')
  const [totalStaked, setTotalStaked] = useState('0')
  const [apr, setApr] = useState('0')
  const [renderKey, setRenderKey] = useState('0')
  const [harvestLoading, setHarvestLoading] = useState(false)

  const farmAddress = addressesByChain.FARM[chainId] || addressesByChain.FARM['56']
  const lpAddress = farmData.addressByChain[chainId] || farmData.addressByChain['56']
  const farmContract = useContract(farmAddress, FarmContract.abi, true)
  const lpTokenContract = useContract(lpAddress, IBEP20Contract.abi, true)
  const bnbContract = useContract(addressesByChain.BNB[chainId], IBEP20Contract.abi, true)

  /* ** CONSTANTS ** */
  // Production
  const inactive = false

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRenderKey(`${Math.random()}`)
    }, 50000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    async function loadData() {
      if (account) {
        // Load User staking balance
        const userData = await farmContract?.callStatic.userInfo(_pid, account)
        setStakingBalance(userData?.amount || '0')

        // Load User RewardToken Earned
        const userRewardTokenEarned = await farmContract?.callStatic.pendingRewardToken(_pid, account)
        setRewardTokenEarned(userRewardTokenEarned || '0')
        const lpTokenBalanceData = await lpTokenContract?.callStatic.balanceOf(account)
        setLpTokenBalance(lpTokenBalanceData)
        console.log("here")

        // check for approval
        const farmAllowance = await lpTokenContract?.callStatic.allowance(account, farmAddress)
        setFarmEnabled(farmAllowance > 0)
      }

      // check for total staked in farm.
      const stakedInFarm = await lpTokenContract?.callStatic.balanceOf(farmAddress)
      // Calculate APR
      // APR is year return / total staked.
      // yearReturn = rewardTokenPerShare * sharesLocked * blocksPerMonth * monthsPerYear * pricePerRewardToken

      // GET USD released per year per lp token
      const { allocPoint } = await farmContract?.callStatic.poolInfo(_pid)
      const totalAllocPoint = await farmContract?.callStatic.totalAllocPoint()
      const rewardTokenPerBlock = await farmContract?.callStatic.rewardTokenPerBlock()

      const rewardTokenPerBlockForPool = rewardTokenPerBlock.mul(allocPoint).div(totalAllocPoint)

      const usdPerYear = Number(formatFixed(rewardTokenPerBlockForPool, 9)) * (28800 * 365) * Number(rewardTokenPrice)

      // GET value of one LP token.
      // get bnbBalanceOf lp token contract

      const bnbBalanceOfLP = await bnbContract?.callStatic.balanceOf(lpAddress)
      const lpTokenTotalSupply = await lpTokenContract?.callStatic.totalSupply()
      // get value of one LP = bnbBalance * bnbPrice / totalSupply
      const lpValue = token2
        ? bnbBalanceOfLP.mul(BigNumber.from(Number(bnbPrice).toFixed(0))).div(lpTokenTotalSupply)
        : rewardTokenPrice

      const totalValueLocked = formatFixed(stakedInFarm, token2 ? 18 : 9) * Number(lpValue)

      // APR is wrong.
      // APR is value recieved per year / value locked
      // usdPerYearPerShare / lpValue
      const aprr = Number(usdPerYear) / Number(totalValueLocked)
      setApr(getBalanceString(getDecimalAmount(aprr).multipliedBy(100)))
      setTotalStaked(getBalanceString(getDecimalAmount(totalValueLocked)))
    }

    loadData()
  }, [
    account,
    renderKey,
    chainId,
    web3.eth.Contract,
    farmData.addressByChain,
    _pid,
    rewardTokenPrice,
    token1,
    bnbPrice,
    token2,
    bnbContract?.callStatic,
    farmAddress,
    farmContract?.callStatic,
    lpAddress,
    lpTokenContract?.callStatic,
  ])

  const onDismiss = async () => {
    setShowModal(false)
  }

  const HandleEnable = async () => {
    const maxUint256 = ethers.constants.MaxUint256
    try {
      const spender = farmContract.address
      setFarmEnabledPending(true)
      await lpTokenContract.approve(spender, maxUint256)
      toastSuccess('Contract Enabled', 'You can now stake in the pool')
    } catch (e) {
      if (!account) toastError('Error', 'Please connect your wallet first!')
      else toastError('Error', 'Please try again. Confirm the transaction and make sure you are paying enough gas!')
      console.log(e)
    }
    setFarmEnabledPending(false)
    setRenderKey(Math.random())
  }

  const HandleDeposit = async (amount) => {
    const amountToDeposit = getDecimalAmount(amount, token2 ? 18 : 9)
    let message = `Staked! Your funds have been staked in the pool!`
    setHarvestLoading(true)
    try {
      if (amount === 0) {
        message = `Successfully harvested rewardTokens`
      }
      const operation = await farmContract.deposit(_pid, amountToDeposit.toFixed(0).toString())
      await operation.wait(1)
      toastSuccess(message)
      setRenderKey(Math.random())
      onDismiss()
      setHarvestLoading(false)
    } catch (e) {
      console.log(e)
      toastError('Canceled', 'Please try again and confirm the transaction.')
      setHarvestLoading(false)
    }
  }

  const HandleWithdraw = async (amount) => {
    const amountToWithdraw = getDecimalAmount(amount, token2 ? 18 : 9)
    let message = `Unstaked! Your LP Tokens are back in your wallet!`
    try {
      if (amount === 0) {
        message = `Successfully unstaked your tokens`
      }
      const tx = await farmContract.withdraw(_pid, amountToWithdraw.toFixed(0).toString())
      await tx.wait(1)
      toastSuccess(message)
      setRenderKey(Math.random())
      onDismiss()
    } catch (e) {
      console.log(e)
      toastError('Canceled', 'Please try again and confirm the transaction.')
    }
    setHarvestLoading(false)
  }

  const tokenLabel = tokenOverride || token2 ? `${token1}-${token2}` : token1
  const [onDeposit] = useModal(
    <DepositWithdrawModal
      max={new BigNumberJS(formatFixed(lpTokenBalance, 0))}
      title={`Stake ${tokenLabel}`}
      onDismiss={onDismiss}
      onConfirm={HandleDeposit}
      tokenName={tokenLabel}
      addLiquidityUrl={!token2 ? '/#/swap' : '/#/add/BNB/0x683fae4411249Ca05243dfb919c20920f3f5bfE0'}
      decimals={!token2 ? 9 : 18}
    />
  )

  const [onWithdraw] = useModal(
    <DepositWithdrawModal
      max={new BigNumberJS(formatFixed(stakingBalance, 0))}
      title={`Unstake ${tokenLabel}`}
      onDismiss={onDismiss}
      onConfirm={HandleWithdraw}
      tokenName={tokenLabel}
      addLiquidityUrl={!token2 ? '/#/swap' : '/#/add/BNB/0x683fae4411249Ca05243dfb919c20920f3f5bfE0'}
      decimals={!token2 ? 9 : 18}
    />
  )

  return (
    <FormContainer inactive={!showMore}>
      <FormInfo inactive={inactive}>
        <LogoContainer>
          {tokenOverride ? (
            <FirstLogo src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-${tokenOverride.toLowerCase()}.png`} />
          ) : (
            <span>
              <FirstLogo src={`${process.env.REACT_APP_PUBLIC_URL}/images/coins/${token1.toLowerCase()}.png`} />
              {token2 && (
                <SecondLogo src={`${process.env.REACT_APP_PUBLIC_URL}/images/coins/${token2.toLowerCase()}.png`} />
              )}
            </span>
          )}
        </LogoContainer>
        <StackDataContainer>
          {tokenOverride ? (
            <BigLabel>Stake {tokenOverride}</BigLabel>
          ) : (
            <BigLabel>{token2 ? `${token1}-${token2}` : `${token1}`}</BigLabel>
          )}
          <ColoredValue>Earn GHC</ColoredValue>
        </StackDataContainer>
        <StackDataContainer>
          <Label inactive={inactive}>GHC Earned</Label>
          <ColoredValue inactive={inactive}>{Number(formatFixed(rewardTokenEarned, 9)).toFixed(0)}</ColoredValue>
        </StackDataContainer>
        <StackDataContainer>
          <Label>APR</Label>
          <Value>{apr}%</Value>
        </StackDataContainer>
        <StackDataContainer>
          <Label>Total Staked</Label>
          <Value>{`${totalStaked} USD`}</Value>
        </StackDataContainer>
        <SmallStackDataContainer onClick={() => setShowMore(!showMore)}>
          <Details>Details</Details>

          {showMore ? <BlackChevronUp /> : <BlackChevronDown />}
        </SmallStackDataContainer>
      </FormInfo>
      {showMore && !showModal && (
        <DetailsContainer>
          <FarmsDetails>
            <FarmDetailsData>
              <FarmDetailsRow inactive={inactive}>GHC Earned:</FarmDetailsRow>
              <FarmDetailsRow inactive={inactive} colored>
                {Number(formatFixed(rewardTokenEarned, 9)).toFixed(0)}
              </FarmDetailsRow>
              {!inactive && (
                <FarmDetailsRow inactive={inactive} small>
                  {Number(Number(formatFixed(rewardTokenEarned, 9)) * rewardTokenPrice).toFixed(2)}~ USD
                </FarmDetailsRow>
              )}
            </FarmDetailsData>
            <FarmDetailsActions>
              <FormActionButton
                inactive={harvestLoading}
                onClick={() => {
                  HandleDeposit(0)
                }}
              >
                {harvestLoading ? 'Pending...' : 'Harvest'}
              </FormActionButton>
            </FarmDetailsActions>
          </FarmsDetails>
          <FarmsDetails>
            <FarmDetailsData>
              <FarmDetailsRow inactive={inactive} small>
                {tokenOverride || token2 ? `${token1}-${token2}` : token1} Staked
              </FarmDetailsRow>
              <FarmDetailsRow inactive={inactive}>
                {Number(formatFixed(stakingBalance, token2 ? 18 : 9)).toFixed(2)}
              </FarmDetailsRow>
            </FarmDetailsData>
            {!farmEnabled ? (
              <FarmDetailsActions>
                <FormActionButton inactive={farmEnabledPending} onClick={HandleEnable}>
                  {farmEnabledPending ? 'Pending' : 'Enable'}
                </FormActionButton>
              </FarmDetailsActions>
            ) : (
              <FarmDetailsActions>
                <FormActionButton small onClick={onDeposit}>
                  +
                </FormActionButton>
                <FormActionButton small onClick={onWithdraw}>
                  -
                </FormActionButton>
              </FarmDetailsActions>
            )}
          </FarmsDetails>
        </DetailsContainer>
      )}
    </FormContainer>
  )
}

export default Farm

/*

// *Front-End:*
// General status: Integration with smart contracts complete.
// Todos:
// Fix price calculations for GHC - in the document header && in nav bar
// Fix price calculations for GHC to calculate APR.
// Theming
// Footer notes
// Early withdraw penalties.
// General testing
// STRETCH: Allow non Web3 browsers to view data (ie. safari with no metamask) 

*/
