import React, { useState, useMemo, useEffect } from 'react'
import { useActiveWeb3React } from 'hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { usePairs } from 'data/Reserves'
import { Pair } from '@overage69/pancake-sdk-v2'
import { LightCard } from 'components/Card'
import { Dots } from 'components/swap/styleds'
import { Text, Button } from '@pancakeswap-libs/uikit'
import GetPrice from 'utils/getPrice'

import {
  FarmsContainer,
  StakeContainer,
  StakeHeading,
  StakeData,
  StackDataContainer,
  SmallStackDataContainer,
  Logo,
  Label,
  BigLabel,
  Value,
  ColoredValue,
  Details,
  BlackChevronDown,
  BlackChevronUp,
} from './styleds'
import { farmData } from './data'
import Farm from './farm'



const Farms = () => {
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()

  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map((tokens) => ({
        liquidityToken: toV2LiquidityToken(tokens),
        tokens,
      })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair) => Boolean(v2Pair))

  const [rewardTokenPrice, setrewardTokenPrice] = useState('0')
  const [bnbPrice, setBnbPrice] = useState('0')
  
  useEffect(()=>{
    async function getTokenPrices(){
      const rewardTokenClass = new GetPrice()
      await rewardTokenClass.init('rewardToken')
      const {pairPriceA:esbbPrice, pairPriceB: bbprice, price: esprice} = await rewardTokenClass.getLatestPrice()
      setrewardTokenPrice(esprice);
      setBnbPrice(bbprice);
    }
    getTokenPrices()
  }, [])

  return (
    <FarmsContainer>

      <StakeContainer>
        <StakeHeading topMargin>GALACTIC FARMS</StakeHeading>
        {!account &&
        <>
          <br/>
            <p>Connect your wallet to see pools!</p>
          <br/>
        </>
        } 
        {farmData.map((farm) => (
          <Farm key={farm._pid} farmData={farm} rewardTokenPrice={rewardTokenPrice} bnbPrice={bnbPrice}/>
        ))}

      </StakeContainer>
    </FarmsContainer>
  )
}

export default Farms
